import React, { useState } from 'react'
import styles from '../SignUp.module.scss'
import TextField from '@material-ui/core/TextField'
import Regions from '../../../data/regions.json'
import MenuItem from '@material-ui/core/MenuItem'
import RadioGroup from '@material-ui/core/RadioGroup'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import InputMask from 'react-input-mask';
import Radio from '@material-ui/core/Radio';
import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl'
import RequiredField from './requiredField';
import { checkUserckAddressInfo } from '../errorHandling'

type AddressInformationType = {
  newUserData: any
  billingAndShippingAreTheSame: boolean
  handleBillingAndShippingAreTheSame: (value: boolean) => void
  handleAddressInformationChange: (label: string, value: string | number, idx: number) => void
  cx: any
  activeStep: number
  setActiveStep: any
  handleCepConsult: any
}

type NewAddressType = {
  newUserData: any
  handleAddressInformationChange: (label: string, value: string | number, idx: number) => void
  activeStep: any
  errorHandler: any
  handleCepConsult: any
}


const NewAddress: React.FC<NewAddressType> = ({ newUserData, handleAddressInformationChange, activeStep, errorHandler, handleCepConsult }) => {
  return (
    <>
      {errorHandler && (newUserData.customer.addresses[1].firstname) === "" && <RequiredField />}
      <TextField
        variant="outlined"
        value={newUserData.customer.addresses[1].firstname}
        error={errorHandler && newUserData.customer.addresses[1].firstname === ""}
        onChange={(e) => handleAddressInformationChange('firstname', e.target.value, 1)}
        size="small"
        disabled={activeStep === 1 ? false : true}
        fullWidth
        placeholder="Nome" />
      {errorHandler && newUserData.customer.addresses[1].lastname === "" && <RequiredField />}
      <TextField
        variant="outlined"
        value={newUserData.customer.addresses[1].lastname}
        error={errorHandler && newUserData.customer.addresses[1].lastname === ""}
        onChange={(e) => handleAddressInformationChange('lastname', e.target.value, 1)}
        size="small"
        disabled={activeStep === 1 ? false : true}
        fullWidth
        placeholder="Sobrenome" />
      {errorHandler && newUserData.customer.addresses[1].postcode === "" && <RequiredField />}
      <InputMask
        mask="99.999-999"
        disabled={activeStep === 1 ? false : true}
        maskChar=""
        value={newUserData.customer.addresses[1].postcode}
        onChange={(e) => {
          console.log(e.target.value.length)
          if (e.target.value.length === 10) {
            handleCepConsult(e.target.value, 1);
          }
          handleAddressInformationChange('postcode', e.target.value, 1)
        }}>
        {
          () =>
            <TextField
              variant="outlined"
              size="small"
              error={errorHandler && newUserData.customer.addresses[1].postcode === ""}
              disabled={activeStep === 1 ? false : true}
              fullWidth
              placeholder="CEP" />
        }
      </InputMask>
      {errorHandler && newUserData.customer.addresses[1].street[0] === "" && <RequiredField />}
      <TextField
        variant="outlined"
        value={newUserData.customer.addresses[1].street[0]}
        error={errorHandler && newUserData.customer.addresses[1].street[0] === ""}
        onChange={(e) => handleAddressInformationChange('rua', e.target.value, 1)}
        size="small"
        disabled={activeStep === 1 ? false : true}
        fullWidth
        placeholder="Rua" />
      <div style={{ display: 'flex' }}>
        {errorHandler && newUserData.customer.addresses[1]?.street[1] === "" && <div style={{ paddingLeft: '75%' }}><RequiredField /></div>}
      </div>
      <TextField
        variant="outlined"
        value={newUserData.customer.addresses[1]?.street[3]}
        onChange={(e) => handleAddressInformationChange('complemento', e.target.value, 1)}
        size="small"
        disabled={activeStep === 1 ? false : true}
        fullWidth
        style={{ width: '72%', marginRight: '3%' }}
        placeholder="Complemento" />
      <TextField
        variant="outlined"
        value={newUserData.customer.addresses[1]?.street[1]}
        error={errorHandler && newUserData.customer.addresses[1]?.street[1] === ""}
        onChange={(e) => handleAddressInformationChange('numero', e.target.value, 1)}
        size="small"
        disabled={activeStep === 1 ? false : true}
        fullWidth
        style={{ width: '25%' }}
        type="number"
        placeholder="Nº" />
      {errorHandler && newUserData.customer.addresses[1]?.street[2] === "" && <RequiredField />}
      <TextField
        variant="outlined"
        value={newUserData.customer.addresses[1]?.street[2]}
        error={errorHandler && newUserData.customer.addresses[1]?.street[2] === ""}
        onChange={(e) => handleAddressInformationChange('bairro', e.target.value, 1)}
        size="small"
        disabled={activeStep === 1 ? false : true}
        fullWidth
        placeholder="Bairro" />
      {errorHandler && newUserData.customer.addresses[1]?.city === "" && <RequiredField />}
      <TextField
        variant="outlined"
        value={newUserData.customer.addresses[1]?.city}
        error={errorHandler && newUserData.customer.addresses[1]?.city === ""}
        onChange={(e) => handleAddressInformationChange('city', e.target.value, 1)}
        size="small"
        disabled={activeStep === 1 ? false : true}
        fullWidth
        placeholder="Cidade" />
      {errorHandler && newUserData.customer.addresses[1]?.region.region_id === 0 && <RequiredField />}
      <TextField
        variant="outlined"
        value={newUserData.customer.addresses[1]?.region.region_id}
        error={errorHandler && newUserData.customer.addresses[1]?.region.region_id === 0}
        onChange={(e: any, child: any) => handleAddressInformationChange('region', child.props, 1)}
        size="small"
        className={newUserData.customer.addresses[1]?.region.region_id === 0 ? "disabledCSS" : ""}
        disabled={activeStep === 1 ? false : true}
        fullWidth
        select>
        <MenuItem disabled value={0}>Estado</MenuItem>
        {Regions.map(region => {
          return (
            <MenuItem
              id={region.code}
              classes={{ selected: styles.selectedItem }}
              className={styles.menuItem}
              key={region.id}
              value={region.id}>
              {region.name}
            </MenuItem>
          )
        })}
      </TextField>
      {errorHandler && newUserData.customer.addresses[1].telephone === "" && <RequiredField />}
      <InputMask
        mask="(99)999999999"
        disabled={activeStep === 1 ? false : true}
        maskChar=""
        value={newUserData.customer.addresses[1].telephone}

        onChange={(e) => handleAddressInformationChange('telephone', e.target.value, 1)}>
        {
          () =>
            <TextField
              variant="outlined"
              size="small"
              error={errorHandler && newUserData.customer.addresses[1].telephone === ""}
              disabled={activeStep === 1 ? false : true}
              fullWidth
              placeholder="Telefone" />
        }
      </InputMask>
    </>)
}

const AddressInformation: React.FC<AddressInformationType> = ({ newUserData, billingAndShippingAreTheSame, handleBillingAndShippingAreTheSame, handleAddressInformationChange, cx, activeStep, setActiveStep, handleCepConsult }) => {
  const className = cx({
    card: true,
    cardInactive: activeStep === 1 ? false : true
  });
  const [errorHandler, setErrorHandler] = useState(false);
  let countKeyPress = 0;

  return (
    <>
      <div className={className}>
        {errorHandler && (newUserData.customer.addresses[0].postcode === "" || newUserData.customer.addresses[0].postcode.length < 10) && <RequiredField label="CEP inválido" />}
        <InputMask
          mask="99.999-999"
          maskChar=""

          value={newUserData.customer.addresses[0].postcode}
          disabled={activeStep === 1 ? false : true}
          onChange={(e) => {
            console.log(e.target.value.length)
            if (e.target.value.length === 10) {
              handleCepConsult(e.target.value, 0);
            }
            handleAddressInformationChange('postcode', e.target.value, 0)
          }}>
          {
            () =>
              <TextField
                variant="outlined"
                error={errorHandler && (newUserData.customer.addresses[0].postcode === "" || newUserData.customer.addresses[0].postcode.length < 10)}
                size="small"
                disabled={activeStep === 1 ? false : true}
                fullWidth
                placeholder="CEP" />
          }
        </InputMask>
        {errorHandler && newUserData.customer.addresses[0].street[0] === "" && <RequiredField label="Preencha a rua" />}
        <TextField
          variant="outlined"
          value={newUserData.customer.addresses[0].street[0]}
          error={errorHandler && newUserData.customer.addresses[0].street[0] === ""}
          onChange={(e) => handleAddressInformationChange('rua', e.target.value.trimLeft(), 0)}
          size="small"
          disabled={activeStep === 1 ? false : true}
          fullWidth
          placeholder="Rua" />
        <div style={{ display: 'flex' }}>

          {errorHandler && newUserData.customer.addresses[0]?.street[1] === "" && <div style={{ paddingLeft: '75%' }}><RequiredField label={null} /></div>}
        </div>
        <TextField
          variant="outlined"
          value={newUserData.customer.addresses[0]?.street[3]}
          onChange={(e) => handleAddressInformationChange('complemento', e.target.value.trimLeft(), 0)}
          size="small"
          disabled={activeStep === 1 ? false : true}
          fullWidth
          style={{ width: '72%', marginRight: '3%' }}
          placeholder="Complemento" />

        <TextField
          variant="outlined"
          value={newUserData.customer.addresses[0]?.street[1]}
          error={errorHandler && newUserData.customer.addresses[0]?.street[1] === ""}
          type="number"
          onChange={(e) => {
            handleAddressInformationChange('numero',(e.target.value.trimLeft()), 0)
          }}
          size="small"
          disabled={activeStep === 1 ? false : true}
          fullWidth
          style={{ width: '25%' }}
          placeholder="Nº" />
        {errorHandler && newUserData.customer.addresses[0]?.street[2] === "" && <RequiredField label="Preencha o bairro" />}
        <TextField
          variant="outlined"
          value={newUserData.customer.addresses[0]?.street[2]}
          error={errorHandler && newUserData.customer.addresses[0]?.street[2] === ""}
          onChange={(e) => handleAddressInformationChange('bairro', e.target.value.trimLeft(), 0)}
          size="small"
          disabled={activeStep === 1 ? false : true}
          fullWidth
          placeholder="Bairro" />
        {errorHandler && newUserData.customer.addresses[0]?.city === "" && <RequiredField label="Preencha a cidade" />}
        <TextField
          variant="outlined"
          value={newUserData.customer.addresses[0]?.city}
          error={errorHandler && newUserData.customer.addresses[0]?.city === ""}
          onChange={(e: any) => handleAddressInformationChange('city', e.target.value.trimLeft(), 0)}
          size="small"
          disabled={activeStep === 1 ? false : true}
          fullWidth
          placeholder="Cidade" />

        {errorHandler && newUserData.customer.addresses[0]?.region.region_id === 0 && <RequiredField label="Selecione o estado" />}
        <TextField
          variant="outlined"
          value={newUserData.customer.addresses[0]?.region.region_id}
          error={errorHandler && newUserData.customer.addresses[0]?.region.region_id === 0}
          onChange={(e: any, child: any) => handleAddressInformationChange('region', child.props, 0)}
          size="small"
          className={newUserData.customer.addresses[0]?.region.region_id === 0 ? "disabledCSS" : ""}
          disabled={activeStep === 1 ? false : true}
          fullWidth
          select>
          <MenuItem disabled value={0}>Estado</MenuItem>
          {Regions.map(region => {
            return (
              <MenuItem
                id={region.code}
                classes={{ selected: styles.selectedItem }}
                className={styles.menuItem}
                key={region.id}
                value={region.id}>
                {region.name}
              </MenuItem>
            )
          })}
        </TextField>

        <div style={{ display: 'flex', justifyContent: 'center', margin: '10px 0' }}>
          <RadioGroup value={(billingAndShippingAreTheSame) ? 1 : 2}>
            <FormControl component="fieldset">
              <FormControlLabel
                disabled={activeStep === 1 ? false : true}
                value={1}
                onClick={() => { (activeStep === 1) && handleBillingAndShippingAreTheSame(true) || setErrorHandler(false) }}
                control={<Radio size='small' color="primary" classes={{ root: styles.radio }} />}
                label="Enviar para esse endereço"
                classes={{ label: styles.label }}
              />
              <FormControlLabel
                disabled={activeStep === 1 ? false : true}
                value={2}
                onClick={() => { (activeStep === 1) && handleBillingAndShippingAreTheSame(false) || setErrorHandler(false) }}
                control={<Radio size='small' color="primary" classes={{ root: styles.radio }} />}
                label="Enviar para endereço diferente"
                classes={{ label: styles.label }}
              />
            </FormControl>
          </RadioGroup>
        </div>
        {!billingAndShippingAreTheSame &&
          <NewAddress newUserData={newUserData} handleAddressInformationChange={handleAddressInformationChange} activeStep={activeStep} errorHandler={errorHandler} handleCepConsult={handleCepConsult} />}
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <Button
            onClick={() => setActiveStep(0)}
            disabled={activeStep === 1 ? false : true}
            variant="outlined"
            color="primary"
            style={{ fontWeight: 'bold', textTransform: 'none', padding: '5px 25px', marginRight: '15px', width: '35%' }}
          >
            Voltar
          </Button>
          <Button
            onClick={() => {
              const error = checkUserckAddressInfo(newUserData, billingAndShippingAreTheSame);
              if (error) {
                setErrorHandler(true)
              } else {
                setActiveStep(2)
              }
              console.log(error)
              //setActiveStep(2)
            }
            }
            disabled={activeStep === 1 ? false : true}
            variant="outlined"
            style={{ backgroundColor: '#24cb99', fontWeight: 'bold', textTransform: 'none', color: 'white', border: 'none', padding: '5px 25px', width: '35%' }} >
            Próximo
          </Button>
        </div>
      </div>


    </>
  )
}

export default AddressInformation
