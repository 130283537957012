import React from 'react'

type RequiredFieldType =  {
  label: any;
}

const RequiredField:React.FC<RequiredFieldType> = ({label}) => {
  return (
    <div style={{color:'red',marginLeft:'4px',fontSize:'11px'}}>
      {label || 'Campo necessário'}
    </div>
  )
}

export default RequiredField
